<template>
  <div class="app v">
    <div class="padding-08 no-flex" v-if="task">
      <div class="bold padding-08 fc-t">
        <span>{{task.proName}} / {{task.stageName}} / {{task.name}}</span>
        <br />
        <span class="fs-huge">实验记录列表</span>
      </div>
    </div>

    <van-pull-refresh v-model="refreshing" class="flex" style="overflow: hidden auto;" @refresh="reload">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" @load="load">
        <experiment-item v-for="item in store" :experiment="item" :key="item.id" />
        <van-empty v-if="store.length == 0" description="暂无实验记录" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { list } from "@/api/project/experiment";
import { getTaskById } from "@/api/project/task";
import ExperimentItem from "./item";

export default {
  name: "ProjectExp",
  components: { ExperimentItem },
  computed: {
    taskId() {
      return this.$route.params.taskId;
    },
  },
  watch: {
    taskId: {
      handler: function () {
        this.reload();
        this.getTask();
      },
      immediate: true,
    },
  },
  data() {
    return {
      page: 1,
      size: 20,
      total: 0,
      store: [],
      refreshing: false,
      loading: false,
      finished: false,
      task: null,
    };
  },
  methods: {
    load() {
      list({
        current: this.page,
        size: this.size,
        taskId: this.taskId || "",
        orders: [
          { column: "status", asc: true },
          { column: "createTime", asc: false },
        ],
      })
        .then((res) => {
          if (res) {
            this.store.push(...res.content);
            this.total = res.totalElements;
            this.finished = this.store.length >= this.total;
            this.page++;
          }
        })
        .finally(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
    reload() {
      this.page = 1;
      this.store = [];
      this.load();
    },
    getTask() {
      getTaskById(this.taskId).then((res) => {
        if (res) {
          this.task = res;
        }
      });
    },
  },
};
</script>