<template>
  <van-cell is-link :to="`/project/experiment/detail/${experiment.id}`" v-if="experiment">
    <div class="h c" slot="title">
      <div class="bold flex">{{experiment.name}}</div>
      <van-tag :type="status[experiment.status].type" class="no-flex">{{status[experiment.status].name}}</van-tag>
    </div>
    <template #label>
      <div class="gap-b-10 van-multi-ellipsis--l2">{{experiment.target || "暂无实验目的"}}</div>
      <div>由&nbsp;{{experiment.createBy}}&nbsp;于&nbsp;{{experiment.createTime.substr(0, 10)}}&nbsp;创建</div>
    </template>
  </van-cell>
</template>

<script>
import { expEnum } from "@/enums/index";

export default {
  props: {
    experiment: Object,
  },
  data() {
    return {
      status: expEnum,
    };
  },
};
</script>