import request from "@/utils/request";

export function list(params) {
  return request({
    url: "api/proExp",
    method: "GET",
    params,
  });
}

export function getById(params) {
  return request({
    url: `api/proExp/${params}`,
    method: "GET",
  });
}
export function getdevList(params) {
  return request({
    url: `api/dev`,
    method: "GET",
    params
  });
}


export default {
  getById,
  list,
  getdevList
};
